import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../components/globalStyles';
import FilterButton from '../components/filterButton';
import ContactSection from '../components/ContactSection';
import Footer from '../components/footer';
import { URL } from '../utils/utils';
import heroImage from '../images/hero.png';
import Header from '../components/Header';
import { Link } from 'gatsby'
import articlePlaceholder from '../images/default-post-image.svg'


const H1 = styled.h1`
  font-size: 32px;
  text-align: center;
`;

const H2 = styled.h2`
  font-size: 30px;
  color: #101f66;
  position: relative;
  margin-bottom: 83px;
  width: fit-content;
  line-height: initial;

  :after {
    content: "";
    position: absolute;
    width: 100px;
    height: 3px;
    background-color: #ff413d;
    border-radius: 2px;
    bottom: -10px;
    left: ${props => props.withImage ? '0' : '50%'};
    margin-left: ${props => props.withImage ? '0' : '-50px'};
  }
`;

const H3 = styled.h3`
  font-size: 30px;
`;

const H5 = styled.h5`
  font-size: 18px;
`;

const P = styled.p`
  text-align: center;
`;

const TitleWrapper = styled.div`
  background-color: #101f66;
  padding: 120px 5%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  @media(min-width: 1920px) {
    padding: 120px 10%;
  }

  @media(min-width: 2000px) {
    padding: 120px 18%;
  }

  @media(max-width: 1184px) {
    padding: 80px 2.5% 40px;
  }

  a {
    color: #c8d2ff;
    text-decoration: underline;
  }
`;

const LineLeft = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-right: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const LineRight = styled.div`
  height: 1px;
  width: 194px;
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  margin-left: 20px;

  @media(max-width: 1184px) {
    display: none;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const BlogContentWrapper = styled.div`
  padding: 123px 5%;
  background-color: #fff;

  @media(min-width: 1920px) {
    padding: 123px 10%;
  }

  @media(min-width: 2000px) {
    padding: 123px 18%;
  }

  a {
    color: #000;
    text-decoration: underline;
  }
`;

const BlogSection = styled.div`
  display: grid;
  grid-template-columns: ${props => props.withImage ? '2fr 1fr' : '1fr'};
  grid-gap: 70px;
  margin-bottom: 104px;

  p { color: #000; }

  img {
    width: 100%;
    height: auto;
    border-radius: 15px;
  }

  @media(max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ContentWrapper = styled.div`
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-flow: column;
  align-items: ${props => props.withImage ? 'flex-start' : 'center'};
`;

const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 250px));
  grid-gap: 20px;
  margin: 68px 0 0;
`;

const OtherPostsWrapper = styled.div`
  background-color: #101f66;
  background-clip: border-box;
  padding: 122px 5%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
`;

const TextOnImage = styled.div`
  position: absolute;
  background-color: rgba(32, 59, 188, 0.9);
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: .5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px;

  p {
    line-height: 23px;
    color: #fff;
    text-align: center;
    margin: 0 22px;  
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    padding: 15px;

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      padding: 15px;
      display: none;
    }

    p:first-of-type {
      display: inline;
    }

    @media(max-width: 1184px) {
      font-size: 12px;
    }
  }
`;

const BlogPost = styled.div`
  height: 200px;
  position: relative;
  cursor: pointer;

  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  margin: 20px;

  :hover ${TextOnImage} {
    opacity: 1;
  }
`;

const ArticlesSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Img = styled.img`
  height: 100%;
`;

const ContactWrapper = styled.div`
  background-color: #12226e;
  padding: 158px 5% 0;

  @media(max-width: 1184px) {
    padding: 0;

    #kontakt {
      display: none;
    }
  }
`;

const AuthorInfoWrapper = styled.div`
  padding: 123px 5%;
  background-color: #101f66;
  color: #fff;

  @media(min-width: 1920px) {
    padding: 123px 10%;
  }

  @media(min-width: 2000px) {
    padding: 123px 18%;
  }
`;

const AuthorImageWrapper = styled.div`
  width: 150px;
  height: 200px;
  margin-right: 30px;
  overflow: hidden;
  border-radius: 20px 5px 5px 5px;
  display: flex;
  justify-content: center;
  
  @media(max-width: 1184px) {
    width: 120px;
    height: 160px;
    border-radius: 15px 4px 4px 4px;
  }
`;

const AuthorImage = styled.img`
  height: 100%;
`;

const AuthorData = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
`;

const AuthorText = styled.p`
  margin: 20px 0;
  max-width: 500px;
`;

const Button = styled.button`
  padding: 13px 30px;
  font-size: 16px;
  border-radius: 5px;
  background-color: ${props => props.red ? '#ff413d' : '$fff'};
  color: ${props => props.red ? '#fff' : '#14277d'};
  margin-right: 30px;
  cursor: pointer;

  @media(max-width: 1184px) {
    padding: 11px 24px;
    font-size: 11px;
    color: ${props => props.red ? '#fff' : '#0a144b'};
    margin-bottom: 15px;
    margin-right: 15px;
  }
`;


class Blog extends Component {
  state = {
    post: null,
    similarPosts: null,
    areThereSimilarPosts: false
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search).get('publikationen');
    axios.get(`${URL}/api/posts/slug/${urlParams}`)
      .then(response => {
        this.setState({post: response.data});
      })
      .then(() => {
        let fetchPostsUrl = `${URL}/api/posts?`;
        // this.state.post.tag.forEach(tag => {
        //   fetchPostsUrl += `tags[]=${tag.name}&`
        // });
        axios.get(fetchPostsUrl)
          .then(response => {
            this.setState({similarPosts: response.data});
          })
          .then(() => {
            if(this.state.similarPosts.length >= 2) {
              return this.setState({areThereSimilarPosts: true});
            }
          })
          .catch(err => console.log(err));
      })
      .catch(err => console.log(err));
    this.initMap();

  }

  initMap = () => {
    const lat = 47.351437;
    const lng = 8.576569;
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat, lng },
      zoom: 16,
      gestureHandling: 'greedy'
    });

    const marker = new window.google.maps.Marker({
      position: { lat, lng },
      url: 'https://goo.gl/maps/jAjd2CYAeWy',
      map: map
    });

    marker.addListener('click', function () {
      window.open(marker.url, '_blank');
    });
  }

  render() {
    let title, publishedAtDate, author, description, filters, blogContent, similarPosts;
    if(this.state.post) {
      title = this.state.post.name;
      publishedAtDate = this.state.post.published_at.toString().slice(0, 10);
      author = this.state.post.author.name;
      description = this.state.post.description;
      filters = this.state.post.tag.map(tag => (
        <FilterButton
          key={tag.id}
          checked
          name={tag.name}
          />
      ))
      blogContent = this.state.post.blog_section.map(section => (
        <BlogSection
          key={section.id}
          withImage={section.image_url}>
          <ContentWrapper withImage={section.image_url}>
            <H2 withImage={section.image_url}>
              {section.name}
            </H2>
            <div dangerouslySetInnerHTML={{ __html: section.content }}></div>
          </ContentWrapper>
          {section.image_url && <img src={section.image_url} alt={section.image_alt_text || section.name} />}
        </BlogSection>
      ))
    }

    if(this.state.similarPosts) {
      similarPosts = this.state.similarPosts.splice(0, 3).map(post => {
        if(this.state.post.id !== post.id) {
          return (
            <a
              key={post.id}
              href={`/aktuell/?publikationen=${post.slug}`}>
              <BlogPost>
                {<Img src={post.image_url || articlePlaceholder} alt={post.image_alt_text || post.name} />}
                <TextOnImage>
                  <H5>{post.name}</H5>
                  <p dangerouslySetInnerHTML={{ __html: post.description }}></p>
                </TextOnImage>
              </BlogPost>
            </a>
          )
        } else {
          return '';
        }
      })
    }

    return (
      <React.Fragment>
        <Helmet>
          <meta property="og:title" content="Aorten GefässZentrum Hirslanden Zürich" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="http://gefaess.ch/aktuell" />
          <meta property="og:image" content={heroImage} />
          <meta property="og:keywords" content="Gefässchirurgie, Aortenchirurgie, Gefässmedizin, Gefässspezialist Zürich" />
        </Helmet>
        <GlobalStyles background='transparent' />
        <Header />
        <TitleWrapper>
          <Title>
            <LineLeft />
            <H1>{title}</H1>
            <LineRight />
          </Title>
          <P>{publishedAtDate}, {author}</P>
          <P dangerouslySetInnerHTML={{ __html: description }}></P>
        </TitleWrapper>
        <BlogContentWrapper>
          {blogContent}
          <FilterWrapper>
            {filters}
          </FilterWrapper>
        </BlogContentWrapper>

        <AuthorInfoWrapper>
          <H3>Autor</H3>
          <AuthorData>
            <AuthorImageWrapper>
              <AuthorImage src={this.state.post && `${URL}${this.state.post.author.image_url}`} alt="author" />
            </AuthorImageWrapper>
            <div>
              <H5>{this.state.post && this.state.post.author.name}</H5>
              <AuthorText dangerouslySetInnerHTML={{ __html: this.state.post && this.state.post.author.short_description }}></AuthorText>
              <Link to={`/${this.state.post && this.state.post.author.short_name}`}>
                <Button>Mehr Erfahren</Button>
              </Link>
              <Link to={`/${this.state.post && this.state.post.author.short_name}#kontakt`}>
                <Button red>{this.state.post && this.state.post.author.name} kontaktieren</Button>
              </Link>
            </div>
          </AuthorData>
        </AuthorInfoWrapper>

        {this.state.areThereSimilarPosts && (
          <OtherPostsWrapper>
            <H3>Entdecken Sie weitere Blogs</H3>
            <ArticlesSection>
              {similarPosts}
            </ArticlesSection>
          </OtherPostsWrapper>
        )}

        <ContactWrapper>
          <ContactSection title="Aorten- und GefässZentrum" />
        </ContactWrapper>
        <Footer />
      </React.Fragment>
    )
  }
}

export default Blog;
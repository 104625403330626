import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.button`
  padding: 5px;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ff413d;
  color: #ff413d;
  outline: none;
  cursor: default;
  // background-color: ${props => props.checked ? "#ff413d" : "#203bbc"};

  @media(max-width: 1184px) {
    padding: 5px;
    font-size: 13px;
  }
`;

const filterButton = props => (
  <StyledWrapper
    checked={props.checked}
    onClick={props.onClick}>
    {props.name}
  </StyledWrapper>
)

export default filterButton;
